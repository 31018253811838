<template>
  <div>
    <div class="box" v-show="comes">
      <img
        class="cha"
        @click="close"
        src="../assets/img/ding/icon-close.png"
        alt=""
      />
      <div class="box-main">
        <div class="main-nav">
          <h1>投诉</h1>
        </div>
        <!-- 订单号 -->
        <span class="tiao"></span>
        <div class="order">
          订单号:#{{ detailcomplain.订单号 }}
          <span class="take">{{ detailcomplain.服务状态 }}</span>
        </div>
        <div class="order-main">
          <div class="hezuo">
            <div class="p1">
              <span class="title">合作单位</span>
              <span class="title-main">{{ detailcomplain.合作单位 }}</span>
            </div>
            <div class="xiangmu">
              <span class="title">服务项目</span>
              <i class="title-main yanse">{{ detailcomplain.服务项目 }}</i>
            </div>
          </div>

          <div class="diBox">
            <span class="remTit">任务点&#12288;</span>
            <span class="diTxt">{{ detailcomplain.事故地点 }}</span>
          </div>

          <div class="diBox" v-if="detailcomplain.目的地">
            <span class="remTit">目的地&#12288;</span>
            <div class="diTxt">{{ detailcomplain.目的地 }}</div>
          </div>
          <div class="diBox">
            <i class="icon">*</i>
            <span class="remTit">接单人员</span>
            <span class="diTxt1"
              >{{ detailcomplain.接单公司 }}{{ detailcomplain.接单人员 }}</span
            >
          </div>
        </div>
        <!-- 车主信息 -->
        <div class="owner">
          <span class="tiao1"></span>
          车主信息
        </div>
        <div class="owner-main">
          <div class="hezuo">
            <p class="p2">
              <span class="title">车主信息</span>
              <span class="title-main">{{ detailcomplain.车主姓名 }}</span>
            </p>
            <p class="xiangmu">
              <span class="title">车牌号&#12288;</span>
              <span class="title-main">{{ detailcomplain.车牌号 }}</span>
            </p>
          </div>

          <div class="hezuo">
            <p class="p2">
              <span class="title">手机号&#12288;</span>
              <span class="title-main">{{ detailcomplain.手机号 }}</span>
            </p>
            <p class="xiangmu">
              <span class="title" v-if="detailcomplain.编号"
                >编号&#12288;&#12288;</span
              >
              <span class="title-main">{{ detailcomplain.编号 }}</span>
            </p>
          </div>

          <div class="remBox" v-if="detailcomplain.订单备注">
            <label class="remTit">订单备注</label>
            <div class="remTxt">{{ detailcomplain.订单备注 }}</div>
          </div>
        </div>

        <!-- 相关费用 -->
        <div class="owner">
          <span class="tiao1"></span>
          相关费用
        </div>
        <!-- <table>
          <tr>
            <td class="toll">收费标准</td>
            <td class="milage">{{ detailcomplain.收费标准 }}</td>
            <td class="avoid" v-if="detailcomplain.免托">
              {{ detailcomplain.免托 }}
            </td>
            <td class="toll" v-if="detailcomplain.流转标准">流转标准</td>
            <td class="milage1" v-if="detailcomplain.流转标准">
              {{ detailcomplain.流转标准 }}
            </td>
          </tr>
          <tr>
            <td class="toll" v-if="detailcomplain.预估拖车距离">拖车距离</td>
            <td class="milage" v-if="detailcomplain.拖车距离">
              {{ detailcomplain.拖车距离 }}
            </td>
            <td class="avoid" v-if="detailcomplain.预估拖车距离">
              预估{{ detailcomplain.预估拖车距离 }}
            </td>
            <td class="toll" v-if="detailcomplain.预估到达距离">到达距离</td>
            <td class="milage1">{{ detailcomplain.预估到达距离 }}</td>
          </tr>
        </table> -->

        <div class="feiBox">
          <div class="nor1">
            <span class="norm">收费标准</span>
            <span class="normMain">{{ detailcomplain.收费标准 }}</span>
            <span class="tuo" v-if="detailcomplain.免托">{{
              detailcomplain.免托
            }}</span>
          </div>
          <div class="nor2" v-if="detailcomplain.流转标准">
            <span class="norm">流转标准</span>
            <span class="normMain">{{ detailcomplain.流转标准 }}</span>
          </div>
          <div class="nor3" v-if="detailcomplain.预估拖车距离">
            <span class="norm">拖车距离</span>
            <span class="normMain" v-if="detailcomplain.预估拖车距离"
              >预估：{{ detailcomplain.预估拖车距离 }}</span
            >
            <span class="normMain" v-if="detailcomplain.预估到达距离"
              >实际：{{ detailcomplain.预估到达距离 }}</span
            >
          </div>
          <div class="nor4" v-if="detailcomplain.目的地">
            <span class="norm">拖车距离</span>
            <span class="normMain"
              >预估：{{ detailcomplain.预估拖车距离 }}</span
            >
            <span class="normMain" v-if="detailcomplain.拖车距离"
              >实际：{{ detailcomplain.拖车距离 }}</span
            >
          </div>
        </div>

        <div class="cause">
          <label class="cause-label" for="">投诉类型</label>
          <el-select class="input-unit" v-model="comtype" @change="handOption1">
            <el-option
              v-for="item in comtypelist"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
        <div class="cause1">
          <label class="cause-label" for="">投诉类别</label>
          <el-select class="input-unit" v-model="comlei">
            <el-option
              v-for="item in comleilist"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
        <div class="cause1">
          <label class="cause-label" for="">投诉原因</label>
          <el-input
            v-model="input3"
            class="cause-input"
            placeholder="请输入投诉原因"
          />
        </div>
        <div class="cause1">
          <label class="cause-label" for="">内容描述</label>
          <el-input
            v-model="textarea1"
            class="cause-text"
            autosize
            type="textarea"
            placeholder="请输入内容描述"
          />
        </div>
        <div class="btn">
          <!-- <button class="set" @click="subcomplain">保存</button> -->
          <el-button class="set" :plain="true" @click="subcomplain"
            >保存</el-button
          >
          <button class="cancel" @click="close">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "../api/orderDetail";
import { ref } from "vue";
const input3 = ref("");
const textarea1 = ref("");

export default {
  props: ["iscome", "complainId"],
  data() {
    return {
      input3: "",
      textarea1: "",
      comes: this.iscome,
      detailcomplain: {},
      comtype: "",
      comlei: "",
      comtypelist: [
        {
          id: 0,
          name: "服务质量",
        },
        {
          id: 1,
          name: "车损",
        },
      ],
      comleilist: [],
    };
  },
  methods: {
    handOption1(value) {
      console.log("选中", value);
      if (value === '服务质量') {
        this.comleilist = [
          {
            id: 0,
            name: "服务态度",
          },
          {
            id: 1,
            name: "时效",
          },
          {
            id: 2,
            name: "其他",
          }
        ];
        this.comlei = '服务态度';
      } else if (value === '车损') {
        this.comleilist = [
          {
            id: 0,
            name: "故障车",
          },
          {
            id: 1,
            name: "商品车",
          },
        ];
        this.comlei = '故障车';
      }
    },
    close() {
      this.comes = false;
      // this.come = !this.come;
    },
    async subcomplain() {

      if (this.input3 == "") {
        // alert('请填写投诉原因');
        this.$message.warning("请填写投诉原因");
        return false;
      }
      if (this.textarea1 == "") {
        // alert('请填写具体内容');
        this.$message.warning("请填写具体内容");
        return false;
      }
      let res = await api.orderComplain(
        this.complainId,
        this.input3,
        this.textarea1,
        this.comtype,
        this.comlei
      );
      if (res.code == 1) {
        this.$message.success("保存成功");
        this.$emit("complainsx", 1);
        this.close();
      } else {
        this.$message.warning(res.message);
        return;
      }
    },
  },
  watch: {
    iscome(newval) {
      //console.log('---',newval);
      this.comes = newval;
    },
    async complainId(newval) {
      //console.log('订单号',newval);
      let res = await api.OrderDetail(newval);
      this.detailcomplain = res.data;

      let res1 = await api.ComplaintDetail(newval);
      //this.complaint = res1.data;
      this.input3 = res1.data.title;
      this.textarea1 = res1.data.content;
      this.comtype = res1.data.type;
      this.handOption1(this.comtype);
      this.comlei = res1.data.lei;

      //console.log(res1);
    },
  },
};
</script>

<style lang="less" scoped>
// 相关费用
.feiBox {
  margin-left: 20px;
}
.nor1,
.nor2,
.nor3,
.nor4 {
  width: 660px;
  border: 1px solid #dadfe6;
}
.nor2 {
  border-top: 0;
}
.nor3 {
  border-top: 0;
}
.nor4 {
  border-top: 0;
}
.norm,
.roam,
.tuo1,
.tuo2 {
  width: 100px;
  height: 50px;
  display: inline-block;
  line-height: 50px;
  text-align: center;
  background-color: #f8fafc;
  color: #2a3346;
  font-size: 16px;
  // border: 1px solid #DADFE6;
  // border-left: 1px solid #dadfe6;
  border-right: 1px solid #dadfe6;
  box-sizing: border-box;
}
.norm {
  border-right: 1px solid #dadfe6;
}
.roam {
  border-top: 0;
}
.normMain,
.tuo,
.roamMain,
.tuo1Main,
.tuo2Main {
  height: 50px;
  line-height: 50px;
  vertical-align: top;
  padding-left: 10px;
  display: inline-block;
  // padding-right: 15px;
  display: inline-block;
  font-size: 14px;
  // border: 1px solid #DADFE6;
  box-sizing: border-box;
  border-left: 0;
}
.normMain {
  width: 220px;
}

.tuo1Main {
  width: 165px;
  border-right: 1px solid #dadfe6;
}
.roamMain,
.tuo2Main {
  width: 180px;
}

.box {
  width: 760px;
  height: 920px;
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 22;
  // background-color: #fff;
}
.cha {
  float: left;
  width: 103px;
  height: 103px;
  margin-top: 36px;
  margin-left: -29px;
}
.box-main {
  width: 720px;
  height: 920px;
  background: #fff;
  margin-left: 40px;
  // margin-top: -21px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;
}
.main-nav {
  width: 600px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
}
h1 {
  line-height: 100px;
  padding-top: 20px;
  height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;

  color: #2a3346;
  opacity: 1;
}
i {
  font-style: normal;
}
.xin {
  width: 32px;
  height: 21px;
  font-style: normal;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2c68ff;
  position: absolute;
  top: 40px;
  right: 20px;
  opacity: 1;
}
.shua {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 30px;
  right: 60px;
}
.order {
  //width: 280px;
  height: 21px;
  font-size: 16px;
  margin-left: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 21px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
}
.take {
  display: inline-block;
  // width: 80px;
  height: 22px;
  margin-left: 6px;
  box-sizing: border-box;
  background: #e8f3fe;
  opacity: 1;
  // text-align: center;
  padding: 0 7px;
  border-radius: 2px;
  color: #2c68ff;
  font-size: 14px;
}
.order-main {
  opacity: 1;
}
.p1 {
  // margin-left: 30px;
  // padding-left: 7px;
  margin-bottom: 10px;
  width: 400px;
  box-sizing: border-box;
}
.xiangmu {
  margin-left: 20px;
}
.yanse {
  color: #2c68ff;
}

.remBox,
.diBox {
  width: 660px;
  display: flex;
  // flex-direction: column;
  margin-left: 37px;
  font-size: 14px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 10px;
}
.remTit {
  margin-right: 30px;
}
.diTxt {
  color: #2c68ff;
}
.remTxt {
  width: 400px;
}
.diTxt {
  width: 400px;
}

.hezuo {
  display: flex;
}

.p2 {
  margin-left: 30px;
  margin-bottom: 10px;
  width: 400px;
  padding-left: 7px;
  box-sizing: border-box;
}
.jiedan {
  width: 500px;
}
.title {
  // width: 56px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
  margin-right: 30px;
}
.title-main {
  width: 126px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  // color: #2a3346;
  opacity: 1;
}
.tit-main {
  width: 70px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2c68ff;
  opacity: 1;
}
.icon {
  width: 5px;
  height: 16px;
  font-size: 12px;
  position: absolute;
  left: -5px;
  top: 1px;
  font-family: MicrosoftYaHei;
  line-height: 17px;
  color: #ff0000;
  opacity: 1;
}
.owner,
.peoper,
.owner1 {
  width: 500px;
  height: 21px;
  font-size: 16px;
  // margin-top: 30px;
  margin-bottom: 10px;
  line-height: 21px;
  padding-left: 7px;
  margin-left: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
}
.owner {
  position: relative;
}
.tiao,
.tiao1 {
  width: 3px;
  height: 15px;
  float: left;
  background: #2c68ff;
  opacity: 1;
  border-radius: 0px;
}
.tiao {
  position: absolute;
  top: 93px;
  left: 20px;
}
.tiao1 {
  position: absolute;
  top: 3px;
  left: 0;
}
.owner2 {
  float: left;
  margin-right: 70px;
}

table,
table tr,
table tr td {
  border: 1px solid #dadfe6;
}
table {
  margin-left: 30px;
  margin-top: 20px;
  min-height: 25px;
  line-height: 25px;
  border-collapse: collapse;
  padding: 2px;
}
tr {
  height: 50px;
}
td {
  box-sizing: border-box;
  font-size: 14px;
  text-align: center;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #727e96;
  opacity: 1;
}
.toll {
  width: 100px;
  background-color: #f8fafc;
}
.milage {
  width: 210px;
  // text-align: left;
  // padding-left: 15px;
}
.avoid {
  width: 100px;
}
.milage1 {
  width: 90px;
  text-align: left;
  padding-left: 15px;
}

.btn {
  width: 180px;
  height: 32px;
  opacity: 1;
  margin-left: 106px;
  margin-top: 44px;
  // background-color: mediumspringgreen;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
  vertical-align: top;
}

.cause {
  margin-left: 30px;
  margin-top: 50px;
}
.cause1 {
  margin-left: 30px;
}
.cause-input {
  height: 40px;
  width: 350px;
}
.cause-label {
  width: 56px;
  height: 19px;
  font-size: 14px;
  margin-right: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 48px;
  color: #2a3346;
  opacity: 1;
}
.cause-text {
  width: 350px;
  height: 40px;
  line-height: 42;
}

.cause1 /deep/ .el-select .el-input__inner {
  width: 350px;
}
.cause /deep/ .el-select .el-input__inner {
  width: 350px;
}
</style>
