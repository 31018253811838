<template>
  <!-- <div @click.stop> -->
  <div class="cancelBox" v-if="ishui">
    <img class="cha" @click="close" src="../assets/img/ding/cha.png" alt="" />
    <div class="cancelMain">
      <p class="cancelTit">回访</p>
      <!-- 车主信息 -->
      <div class="huixin">
        <div class="huixinleft">
          <label class="title">车主信息</label>
          <span class="title-main"
            >{{ detail.车主姓名 }}/{{ detail.手机号
            }}<button
              @click="call(detail.手机号, detail.订单号)"
              class="call"
              v-show="getEmic != ''"
            ></button
          ></span>
        </div>

        <div class="huixinright">
          <label class="title">车牌号</label>
          <span class="title-main">{{ detail.车牌号 }}</span>
        </div>
      </div>

      <!-- 满意度切换 -->
      <div class="manyidu">
        <div class="pingLeft" @click="man = 1" :class="{ active: man === 1 }">
          <img class="pingImg" src="../assets/img/cd/xiao.png" alt="" />
          <span class="pingTit">满意</span>
          <img
            class="duiImg"
            v-if="man === 1"
            src="../assets/img/cd/dui.png"
            alt=""
          />
        </div>
        <div class="pingRight" @click="man = 2" :class="{ active: man === 2 }">
          <img class="pingImg" src="../assets/img/cd/chou.png" alt="" />
          <span class="pingTit">不满意</span>
        </div>
        <img
          class="duiImg2"
          v-if="man === 2"
          src="../assets/img/cd/dui.png"
          alt=""
        />
      </div>

      <!-- 服务态度  私下交易信息 -->
      <div class="information juli">
        <!-- 循环标题 -->
        <div
          class="informationLeft"
          v-for="(data, index) in huifangMain.rating_type"
          :key="index"
        >
          <div class="informationTit">
            {{ data.type_name }}
          </div>
          <div class="xuanTit">
            <!-- 循环选项 -->
            <el-checkbox-group
              v-model="obj[data.checked]"
              @change="
                (val) => {
                  changehui(val, data.id);
                }
              "
            >
              <el-checkbox
                v-for="item in huifangMain.rating_type[index].children"
                :key="item.id"
                :label="item.id"
                size="large"
                :disabled="man === 1 ? true : false"
                :checked="item.is_check == 1?true:false"
              >
                <span>{{ item.type_name }}</span>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>

      <!-- 其他 -->
      <div class="other">
        <div class="informationTit">其他</div>
        <div class="proposal">
          <el-input
            class="text"
            v-model="yijian"
            autosize
            type="textarea"
            placeholder="如意见或建议"
          />
        </div>
      </div>

      <!-- 按钮 -->
      <div class="btn">
        <button class="set" @click="bao(this.ishuiid)">保存</button>
        <button class="cancel" @click="close">取消</button>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import { ref } from "vue";
import * as api from "../api/order";
import * as api1 from "../api/orderDetail";
import * as ola from "../api/olawebsock.js";
export default {
  props: ["ishui", "huifangMain", "ishuiid"],
  data() {
    return {
      detail: {},
      obj: {
        checked0: [],
        checked1: [],
        checked2: [],
        checked3: [],
      },
      man: 1,
      yijian: "",
      biaotiId: "",
      fuwu: "",
      jishi: "",
      shixiao: "",
      zhuanye: "",
      arr: [],
      is_r:0
    };
  },
  methods: {
    close() {
      this.$emit("closehui");
    },
    changehui(id, index) {
      console.log("选中了-----", index, id);
      let sy = [];
      let obj = this.huifangMain.rating_type;
      for (let key in obj) {
        sy.push(obj[key].id);
      }

      if (index == sy[0]) {
        this.fuwu = id;
      } else if (index == sy[1]) {
        this.jishi = id;
      } else if (index == sy[2]) {
        this.shixiao = id;
      } else if (index == sy[3]) {
        this.zhuanye = id;
      }
      console.log("服务---", this.fuwu, this.jishi, this.shixiao, this.zhuanye);
    },
    async bao() {
      console.log("fuzujiande id----------", this.ishuiid);
      console.log(this.huifangMain.rating_type);
      let sy = [];
      let obj = this.huifangMain.rating_type;
      for (let key in obj) {
        sy.push(obj[key].id);
      }

      let rating_type = {
        [sy[0]]: this.fuwu,
        [sy[1]]: this.jishi,
        [sy[2]]: this.shixiao,
        [sy[3]]: this.zhuanye,
      };


      let rescue_id = this.ishuiid;
      let other = this.yijian;

      if(this.is_r == 1)
      {
        let res = await api.saveEvaluateAgain(rating_type, rescue_id, other);
        console.log("保存成功-----", res);
        this.panduan = res;
        if (res.code == 0) {
          this.close();
          this.$message.success(res.msg);
          this.$emit("returnsx", 1);
        } else if (res.code == 1) {
          this.$message.warning(res.msg);
        }
      }else{
        let res = await api.saveEvaluate(rating_type, rescue_id, other);
        console.log("保存成功-----", res);
        this.panduan = res;
        if (res.code == 0) {
          this.close();
          this.$message.success(res.msg);
          this.$emit("returnsx", 1);
        } else if (res.code == 1) {
          this.$message.warning(res.msg);
        }
      }

    },
    call(tel, str) {
      let ue =  this.$store.state.emic;
      let mode = this.$store.state.emicmode;

      console.log("拨打电话", tel);
      ue.call.callout({
        customerNumber:tel.toString(),
        loginType:mode,
        extras:str
      });
    },
  },
  async created() {
    let res_orderDetail = await api1.OrderDetail(this.ishuiid);
    this.detail = res_orderDetail.data;
    console.log("刚进来回访页面", res_orderDetail.data);
    console.log('data：',this.huifangMain)
    if(this.huifangMain.rors.type_score != undefined)
    {
        this.is_r = 1;
        this.yijian = this.huifangMain.rors.others;
        if(this.huifangMain.rors.type_score  == '10')
        {
          this.man = 1;
        }else{
          this.man = 2;
        }

        let rt = this.huifangMain.rors.rating_type;
        for (let key in rt) {
          if(Array.isArray(rt[key]))
          {
             console.log('data',key)
             console.log('checket',rt[key]);
             console.log('------------------');
             this.changehui(rt[key],key);
          }
        }
      

    }else{
      this.is_r = 0
    }
  },
  mounted() {
    console.log(
      "回访满意度---------------------：",
      this.huifangMain,
      this.huifangMain.rating_type
    );
  },
  computed: {
    getEmic() {
      return this.$store.state.emic;
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.cancelBox {
  width: 620px;
  position: absolute;
  top: 0px;
  right: 0;

  z-index: 7;
}
.cancelMain {
  width: 620px;
  height: 920px;
  background-color: #fff;
  float: right;
  position: relative;
  box-shadow: 0px -15px 20px rgba(179, 189, 195, 0.4);
  padding: 24px;
  box-sizing: border-box;
  overflow-y: scroll;
}
.cancelMain::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.cha {
  width: 40px;
  height: 40px;
  position: absolute;
  left: -40px;
  top: 70px;
}
.cancelTit {
  font-size: 20px;
  font-weight: bold;
}
.information {
  width: 580px;
  margin-top: 10px;

  // display: flex;
  // align-items: center;
  // justify-content: center;
}
.manyidu {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.informationLeft,
.informationRight {
  width: 274px;
  height: 160px;
  background-color: #f8f8f8;
  padding: 10px;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 24px;
  // margin-right: 10px;
}
.informationLeft:nth-child(2) {
  margin-left: 20px;
}
.informationLeft:last-child {
  margin-left: 20px;
}

.pingLeft {
  width: 274px;
  height: 90px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
}
.duiImg {
  width: 41px;
  height: 41px;
  position: absolute;
  top: 0;
  right: 0;
}
.pingRight {
  width: 274px;
  height: 90px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
}
.duiImg2 {
  width: 41px;
  height: 41px;
  position: absolute;
  // top: 70px;
  top: 110px;
  right: 25px;
}
.informationRight,
.pingRight {
  margin-left: 24px;
}
.juli {
  margin-top: 24px;
}
.informationTit {
  padding-left: 10px;
  font-size: 16px;
  border-left: 2px solid #3c67f6;
  box-sizing: border-box;
  font-weight: 500;
}
.xuanTit {
  margin-top: 10px;
}

.btn {
  margin-top: 46px;
  // padding-left: 10px;
  box-sizing: border-box;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
  vertical-align: top;
}

// 其他
.other {
  padding: 16px;
  box-sizing: border-box;
  background-color: #f8f8f8;
  margin-top: 24px;
}
.proposal {
  margin-top: 10px;
}
.qitaTit {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: 32px;
  padding: 2px 12px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
}
.active {
  border: 1px solid #2c68ff;
}
.pingImg {
  width: 33px;
  height: 33px;
}
.pingTit {
  font-size: 16px;
  margin-left: 12px;
}
.xuanTit /deep/ .el-checkbox.el-checkbox--large {
  width: 200px;
}

.xuanBox {
  display: flex;
}

.huixin {
  display: flex;
  margin-top: 20px;
}
.huixinleft,
.huixinright {
  width: 274px;
}
.huixinright {
  margin-left: 24px;
}
.title {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2a3346;
  opacity: 1;
  margin-right: 20px;
}
.title-main,
.title-main2 {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  opacity: 1;
  word-break: break-all;
}
.title-main2 {
  width: 126px;
}
</style>